import React, { useState } from "react";
import s from "../../pages/Students/students.module.scss";
import { AppInput } from "../Input/Input";
import { AppSelect } from "../Select/Select";
import UploadAvatar from "../UploadAvatar/UploadAvatar";
import { AppButton } from "../Button/Button";
import { AppModal } from "../Modal/Modal";
import { useForm } from "@mantine/form";
import { useTranslation } from "react-i18next";
import { useAddStudentMutation, useUpdateStudentAvatarMutation } from "../../app/api/student";
import { useMediaQuery } from "@mantine/hooks";
import { Notification } from "../Notification/notification";
import { useGetCityQuery, useGetMatnasQuery } from "../../app/api/classes";
import { useGetSchoolsQuery } from "../../app/api/schools";

type FormValues = {
  fullName: string;
  city: string;
  school: string;
  matnas: string;
  ID?: string;
  phoneNumber: string;
  email?: string;
  WhatsAppLink?: string;
  institutionType?: InstitutionType | null;
};

const institutionTypes = ["matnas", "school"] as const;
type InstitutionType = (typeof institutionTypes)[number];

type CreateStudentModalProps = {
  opened: boolean;
  onClose: () => void;
};

const initialFormValues: FormValues = {
  fullName: "",
  city: "",
  school: "",
  matnas: "",
  ID: "",
  phoneNumber: "",
  email: "",
  WhatsAppLink: "",
  institutionType: null,
};

export const CreateStudentModal = ({ opened, onClose }: CreateStudentModalProps) => {
  const { getInputProps, onSubmit, reset, setValues, values } = useForm<FormValues>({
    initialValues: initialFormValues,
    validate: {
      fullName: (value) => {
        if (!value) {
          return t("studentsPage.validations.fullNameRequired");
        }
      },
      city(value) {
        if (!value) {
          return t("studentsPage.validations.cityRequired");
        }
      },
      institutionType(value) {
        if (!value) {
          return t("studentsPage.validations.institutionTypeRequired");
        }
      },
      matnas(value, values) {
        if (!value && values.institutionType === "matnas") {
          return t("studentsPage.validations.emailRequired");
        }
      },
      school(value, values) {
        if (!value && values.institutionType === "school") {
          return t("studentsPage.validations.schoolRequired");
        }
      },
      phoneNumber(value) {
        if (!value) {
          return t("studentsPage.validations.phoneRequired");
        }
      },
    },
  });
  const { t } = useTranslation();
  const desktop = useMediaQuery("(min-width: 992px)");
  const [avatarFile, setAvatarFile] = useState<File | null>(null);
  const [isStudentAdded, setIsStudentAdded] = useState<boolean>(false);
  const [updateStudentAvatar] = useUpdateStudentAvatarMutation();
  const [addStudent] = useAddStudentMutation();
  const { data: cities } = useGetCityQuery();
  const { data: matnases } = useGetMatnasQuery(values.city ? { cityId: values.city } : {});
  const { data: schools } = useGetSchoolsQuery(values.city ? { cityId: values.city } : {});

  const addStudentHandler = async (values: FormValues) => {
    try {
      const response = await addStudent({
        fullName: values.fullName,
        cityId: values.city as string,
        matnasId: values.matnas ? values.matnas : undefined,
        schoolId: values.school ? values.school : undefined,
        ID: values.ID ? values.ID : undefined,
        phoneNumber: values.phoneNumber,
        email: values.email? values.email : undefined,
        WhatsAppLink: values.WhatsAppLink? values.WhatsAppLink : undefined,
      }).unwrap();

      if (avatarFile) {
        const formData = new FormData();
        formData.append("avatar", avatarFile);
        try {
          if (response?._id) {
            await updateStudentAvatar({
              id: response._id,
              body: formData,
            }).unwrap();
          }
        } catch (error) {
        } finally {
        }
      }
      setIsStudentAdded(true);
    } catch (error) {
    } finally {
      reset();
      onClose();
      setTimeout(() => {
        setIsStudentAdded(false);
      }, 3000);
    }
  };
  return (
    <>
      {isStudentAdded && <Notification text={t("studentsPage.notification.studentAdded")} />}
      <AppModal
        status={opened}
        onClose={() => {
          reset();
          onClose();
        }}
        size={desktop ? "md" : "xs"}
      >
        <div className={s.modal}>
          <div className={s.modalHeader}>{t("studentsPage.buttons.addStudent")}</div>
          <form onSubmit={onSubmit((values) => addStudentHandler(values))} className={s.form}>
            <AppInput placeholder={t("studentsPage.form.fullNamePlaceholder")} {...getInputProps("fullName")} />
            {cities && (
              <AppSelect
                data={cities.map((city) => ({ value: city._id, label: city.name }))}
                placeholder={t("studentsPage.form.cityPlaceholder")}
                {...getInputProps("city")}
              />
            )}
            <AppSelect
              data={institutionTypes.map((type) => ({ value: type, label: type }))}
              placeholder={t("studentsPage.form.institutionTypePlaceholder")}
              {...getInputProps("institutionType")}
              onChange={(value) => {
                setValues({ institutionType: value as InstitutionType, matnas: "", school: "" });
              }}
            />
            {values.institutionType === "matnas" && (
              <AppSelect
                data={matnases ? matnases.map((matnas) => ({ value: matnas._id, label: matnas.name })) : []}
                placeholder={t("studentsPage.form.matnasPlaceholder")}
                {...getInputProps("matnas")}
              />
            )}
            {values.institutionType === "school" && (
              <AppSelect
                data={schools ? schools.map((school) => ({ value: school._id, label: school.name })) : []}
                placeholder={t("studentsPage.form.schoolPlaceholder")}
                {...getInputProps("school")}
              />
            )}
            <AppInput placeholder={t("studentsPage.form.idPlaceholder")} {...getInputProps("ID")} />
            <AppInput type={"number"} placeholder={t("studentsPage.form.phoneNumberPlaceholder")} {...getInputProps("phoneNumber")} />
            <AppInput placeholder={t("studentsPage.form.emailPlaceholder")} {...getInputProps("email")} />
            <AppInput placeholder={t("studentsPage.form.whatsAppLinkPlaceholder")} {...getInputProps("WhatsAppLink")} />
            <UploadAvatar avatarFile={avatarFile} setAvatarFile={setAvatarFile} />
            <div className={s.formBtns}>
              <AppButton
                title={t("general.actions.cancel")}
                variant={"outline"}
                onClick={() => {
                  onClose();
                  reset();
                  setAvatarFile(null);
                }}
              />
              <AppButton title={t("studentsPage.form.submit")} variant={"filled"} type={"submit"} />
            </div>
          </form>
        </div>
      </AppModal>
    </>
  );
};
